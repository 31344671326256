
// 表单公用间距
export const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
export const tailFormItemLayout = {
    wrapperCol: { span: 24 },
};

// 新手导引Cookie保存时间长度
export const LEARN_TIME = 9999999999;

export const MAX_BANKCARD_LENGTH = 5;

// 退出需要清除Cookie的key值
// 不需要清空的Cookie的key值 ["learnStep"]
export const CLEAR_COOKIE_KEY = [
    "personMessage",
    "systemMessage",
    "emailTime",
    "phoneTime",
    "dateTime",
    "ALBtype",
    "isAlbSecond",
    "isAlbThird",
    "isThird",
    "isWCThird",
    "withdrawLastTime",
];

export const BANKING_HISTORY_SUB_WITHDRAWAL_STATUS_TEXTCOLOR = ['', '#FABE47', '#FABE47', '#EB2121', '#34C759', '#FABE47', '#FABE47', '#FABE47'];
export const BANKING_HISTORY_SUB_WITHDRAWAL_STATUS_TEXT = ['', '处理中', '处理中', '提款失败', '提款成功', '处理中', '处理中', '处理中'];

export const isWindowOpenGame = ["IMOPT",  "SGW", "NLE", "LBS"]; //不支持iframe的游戏放进去
export const isCookieNoOpenGame = ["MGSQF", "BGG", "AGL"]; //不支持第三方cookie

export const SportsbookGameCatCode = 'Sportsbook';
export const ESportsGameCatCode = 'ESports';
export const InstantGamesGameCatCode = 'InstantGames';
export const LiveCasinoGameCatCode = 'LiveCasino';
export const P2PGameCatCode = 'P2P';
export const SlotGameCatCode = 'Slot';
export const KenoLotteryGameCatCode = 'KenoLottery';

// inbox
export const SYSMSG_TYPE_ID_ENUM = {ALL: '0', BANKING: '7', PRODUCTS: '8', PROMOTIONS: '9', GENERAL: '10'};
export const INBOXMSG_TYPE_ID_ENUM = {TRANSACTION: '2', PERSONAL: '1', DISCOUNT: '3' };
export const INBOXMSG_TYPE_OPTION_ID_ENUM = {ALL:'0', DEPOSIT:'3',  TRANSFER:'4',REST:'4' , WITHDRAWAL:'5', BONUS:'6'}; // MUST be string, depends on getPersonData's design/behavior

// Deposit
export const ARY_NEED_PII_KYC_CHECK = ['PPB', 'BC', 'BCM', 'OA', 'WC', 'UP', 'QQ', 'ODP', 'CTC', 'CC'];
export const PAYTYPE_OF_ONLINE_ALIPAY = 'OA';
export const PAYTYPE_OF_ODP = 'ODP'; // means One Deposit
export const DEPOSIT_APPLY_ERRMSG_FOR_VAL18035withNoKYC = '系统错误，请稍后再试。';
export const mapCode2LinkOfQQDigitalCoin = {'ebpay_dp':'https://www.ybc9tizp.com/', 'goubaopay_dp':'https://www.fafa0588.com/', 'kdoupay_dp':'https://kdxz51888.kpahdn.com/', 'hipay_dp':'https://www.hiwalletapp.com/download', 'c_808pay_dp':'http://808pay.com/', 'c_988pay_dp':'http://www.988pay.com', 'jdpay_dp':'https://jdpay01.com/'};
export const INVOICE_METHODCODE_OF_CTC = 'INVOICE';
export const DDPAY_METHODCODE = 'DDpay_DP'; // first for PPB(or say P2P)

// OpenGameUrl
export const OPENGAME_VENDOR_SEARCH_KEY = 'vendor';
export const OPENGAME_NAME_SEARCH_KEY = 'name';

